* {
  box-sizing: border-box;
}
html,
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
button,
input,
textarea,
p,
select,
blockquote,
th,
td,
img,
a {
  margin: 0;
  padding: 0;
  font-weight: 300;
  color: #000;
  font-size: 1em;
  word-break: keep-all;
  word-wrap: break-word;
}
html,
body {
  font-size: 16px;
  width: 100%;
  height: 100% !important;
  overflow-x: hidden;
  overscroll-behavior: contain;
}
body.active {
  overflow-y: hidden;
}
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
a:hover {
  text-decoration: none;
}
a:active {
  text-decoration: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
img {
  border: 0;
}
button {
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}
input,
select,
textarea {
  border: 1px solid #aaa;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  outline: none;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.ant-pagination-options {
  display: none;
}

.drag {
  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none
}

.scrollTable{height: 100%;overflow-x: auto;width: 100%;overflow-y: hidden;}

.loginContainer{background: #242D31;}
.loginBox{width: 100%;height: 100vh;}
.loginBox > .loginLeftBox{width: 700px;height: 100%;background: rgba(45, 59, 62, 0.60);backdrop-filter: blur(12.149999618530273px);}
.loginBox > .loginLeftBox > .loginItem{width: 100%;top: 40%;position: absolute;justify-content: center;display: grid;}
.loginBox > .loginLeftBox > .loginItem > .loginLogo{width: 100%;display: flex;align-items: center;justify-content: center;}
.loginBox > .loginLeftBox > .loginItem > .loginLogo > span{margin-left: 6px;background: linear-gradient(90deg, #30795E 0%, #1E98E8 100%);background-clip: text;-webkit-background-clip: text;-webkit-text-fill-color: transparent;text-align: center;font-size: 14px;font-weight: 700;}
.loginBox > .loginLeftBox > .loginItem > .loginInput{display: flex;width: 380px;flex-direction: column;align-items: flex-start;gap: 10px;margin-top: 40px;}
.loginBox > .loginLeftBox > .loginItem > .loginInput > input{display: flex;height: 44px;padding: 12px 16px;align-items: center;align-self: stretch;border-radius: 8px;background: #242D31;border: #242D31;color: rgba(255, 255, 255, 0.3);font-size: 14px;font-weight: 400;}
.loginBox > .loginLeftBox > .loginItem > .loginInput > input::placeholder{color: rgba(255, 255, 255, 0.3);font-size: 14px;font-weight: 400;}
.loginBox > .loginLeftBox > .loginItem > .loginButton{display: inline-flex;height: 46px;padding: 0px 13px;justify-content: center;align-items: center;gap: 10px;border-radius: 8px;background: linear-gradient(90deg, #30795E 0%, #1E98E8 100%);border:0;color: #FFF;text-align: center;font-size: 14px;font-weight: 700;margin-top: 40px;}

.sensorStatus.normal{color: #4AA785}
.sensorStatus.unavailable{color: #000000D9}
.sensorStatus.check{color: #FFC555D9}

.flex-between{display: flex;justify-content: space-between;}

.selectChart{border: 1px solid #1890FF}